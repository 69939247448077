<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box">
        <h4 class="page-title">{{ $t(title) }}</h4>
        <div class="page-title-right">
          <b-breadcrumb :items="tranItems" class="m-0"></b-breadcrumb>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    tranItems() {
      return this.items.map((i) => {
        if (i.tran) {
          i.text = this.$t(i.tran);
        }

        return i;
      });
    },
  },
};
</script>
