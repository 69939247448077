export const menuItems = [
  {
    id: 1,
    label: "sidebar.home",
    icon: "ri-home-3-line",
    link: "/home",
  },
  {
    id: 2,
    label: "sidebar.my-templates",
    icon: "ri-dashboard-line",
    link: "/my-templates",
  },
  {
    id: 3,
    label: "sidebar.roles",
    icon: "ri-flag-2-line",
    link: "/roles",
    requiresPermissions: [18],
  },
  {
    id: 4,
    label: "sidebar.users",
    icon: "fe-users",
    link: "/users",
    requiresPermissions: [31],
  },
  {
    id: 5,
    label: "sidebar.companies",
    icon: "ri-building-4-line",
    link: "/companies",
    requiresPermissions: [40],
  },
  {
    id: 6,
    label: "sidebar.cities",
    icon: "ri-building-line",
    link: "/cities",
    requiresPermissions: [11],
  },
  {
    id: 7,
    label: "sidebar.categories",
    icon: "ri-stack-line",
    link: "/categories",
    requiresPermissions: [1],
  },
  {
    id: 8,
    label: "sidebar.templates",
    icon: "ri-folders-line",
    link: "/templates",
    requiresPermissions: [25],
  },
  {
    id: 9,
    label: "sidebar.wizard",
    icon: "ri-task-line",
    link: "/wizard",
  },
];
