<script>
/**
 * Footer Component
 */
export default {};
</script>

<template>
  <!-- Footer Start -->
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">2022 &copy; {{ $appConfig.title }}</div>
        <div class="col-md-6"></div>
      </div>
    </div>
  </footer>
  <!-- end Footer -->
</template>
