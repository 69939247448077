<template>
  <!-- Topbar Start -->
  <div class="navbar-custom">
    <div class="container-fluid">
      <ul class="list-unstyled topnav-menu float-right mb-0">
        <!-- OWNER - refresh via cron, api/templates/refreshall -->
        <li v-if="user && user.owner" class="dropdown d-inline-block">
          <button
            @click.prevent="refreshAllConfirm"
            class="btn btn-danger btn-refresh-all"
          >
            <b-spinner
              v-if="wait"
              variant="red"
              style="width: 22px; height: 22px; opacity: 0.7"
            />
            <template v-else>
              {{ $t("refresh") }}
            </template>
          </button>
        </li>
        <!-- Lang switcher -->
        <b-nav-item-dropdown
          variant="white"
          class="d-none d-lg-inline-block"
          right
          toggle-class="header-item"
        >
          <template v-slot:button-content>
            <img class :src="flag" alt="Header Language" height="16" />
            {{ text }}
          </template>
          <b-dropdown-item
            class="notify-item"
            v-for="(entry, i) in languages"
            :key="`Lang${i}`"
            :value="entry"
            @click="setLanguage(entry.language, entry.title, entry.flag)"
            :link-class="{ active: entry.language === current_language }"
          >
            <img :src="`${entry.flag}`" alt="" class="mr-1" height="12" />
            <span class="align-middle">{{ entry.title }}</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown
          right
          class="notification-list topbar-dropdown nav-item"
          menu-class="profile-dropdown"
          toggle-class="p-0"
          :disabled="userLoading"
        >
          <template class="nav-item" slot="button-content">
            <div class="user-info nav-user mr-0">
              <template v-if="userLoading">
                <b-spinner
                  variant="white"
                  style="width: 28px; height: 28px; opacity: 0.7"
                />
                <span class="pro-user-name ml-1"> ... </span>
              </template>

              <template v-else>
                <img :src="userAva" alt="user-image" class="rounded-circle" />
                <span class="pro-user-name ml-1">
                  <template v-if="user && user.name">
                    {{ user.name }}
                  </template>
                  <i class="mdi mdi-chevron-down"></i>
                </span>
              </template>
            </div>
          </template>

          <b-dropdown-header>
            <h6 v-if="user && user.email" class="text-overflow m-0 py-2">
              {{ user.email }}
            </h6>
          </b-dropdown-header>

          <!-- <b-dropdown-item to="/profile">
            <i class="remixicon-settings-3-line"></i>
            <span>{{ $t("navbar.dropdown.name.list.profile") }}</span>
          </b-dropdown-item> -->

          <b-dropdown-divider></b-dropdown-divider>
          <a class="dropdown-item" href="/logout" @click.prevent="handleLogout">
            <i class="fe-log-out mr-1"></i>
            <span>{{ $t("navbar.dropdown.name.list.logout") }}</span>
          </a>
        </b-nav-item-dropdown>
      </ul>

      <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
        <li>
          <button
            class="button-menu-mobile waves-effect waves-light"
            @click="toggleMenu"
          >
            <i class="fe-menu"></i>
          </button>
        </li>

        <li>
          <!-- Mobile menu toggle (Horizontal Layout)-->
          <a
            class="navbar-toggle nav-link"
            data-toggle="collapse"
            @click="horizonalmenu()"
            data-target="#topnav-menu-content"
          >
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
          <!-- End mobile menu toggle-->
        </li>
      </ul>

      <div class="clearfix"></div>
    </div>
  </div>
  <!-- end Topbar -->
</template>

<script>
/**
 * Topbar component
 */

import { mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
        {
          flag: require("@/assets/images/flags/russia.jpg"),
          language: "ru",
          title: "Русский",
        },
        {
          flag: require("@/assets/images/flags/ukraine.jpg"),
          language: "ua",
          title: "Українська",
        },
      ],
      current_language: this.$i18n.locale,
      text: null,
      flag: null,
      value: null,

      wait: false,
    };
  },
  mounted() {
    // set init lang
    if (this.$store.getters.lang) {
      this.$i18n.locale = this.$store.getters.lang;
    } else {
      this.$i18n.locale = process.env.VUE_APP_I18N_LOCALE;
    }

    this.value = this.languages.find((x) => x.language === this.$i18n.locale);
    this.text = this.value.title;
    this.flag = this.value.flag;
    this.current_language = this.$i18n.locale;
  },
  computed: {
    ...mapGetters(["user", "userLoading"]),
    userAva() {
      return this.user.ava
        ? require("@/assets/images/users/" + this.user.ava)
        : require("@/assets/images/users/avatar_blank.png");
    },
  },
  methods: {
    ...mapActions({
      logout: "logout",
    }),
    handleLogout: async function() {
      try {
        await this.$store.dispatch("logout");
        await this.$router.push("/login");
      } catch (e) {
        console.log("logout error");
      }
    },

    /**
     * Toggle menu
     */
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    /**
     * Set languages
     */
    setLanguage(locale, country, flag) {
      this.$store.dispatch("storeLangCookies", locale);
      this.$store.commit("setlang", locale);
      this.$i18n.locale = locale;
      this.current_language = locale;
      this.text = country;
      this.flag = flag;
    },
    /**
     * Horizontal-toggle menu
     */
    horizonalmenu() {
      let element = document.getElementById("topnav-menu-content");
      element.classList.toggle("show");
    },
    refreshAllConfirm() {
      this.$bvModal
        .msgBoxConfirm(this.$t("modal.want-refresh"), {
          title: this.$t("modal.confirm-refresh"),
          size: "md",
          buttonSize: "md",
          okVariant: "danger",
          okTitle: this.$t("btn.yes"),
          cancelTitle: this.$t("btn.no"),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((sure) => {
          if (sure) {
            this.refreshAll();
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    async refreshAll() {
      this.wait = true;

      try {
        const response = await axios.post("templates/refreshall");
        // console.log("refreshAll, response: ", response.data);
        const msg = `code: ${response.data.code}, result: ${response.data.result}`;
        this.$bvModal.msgBoxOk(msg, {
          title: "Refreshed successful...",
          size: "md",
          buttonSize: "md",
          okVariant: "info",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        });
      } catch (error) {
        console.log("refreshAll, error: ", error);
      } finally {
        this.wait = false;
      }
    },
  },
};
</script>

<style scoped>
.user-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 120px;
}
.btn-refresh-all {
  margin: 16px 16px 0 0;
  min-width: 120px;
  text-align: center;
}
</style>
